import { SidenavParams } from '@zonar-ui/sidenav/lib/sidenav/interfaces';

export const SidenavGlobalConfig: SidenavParams = {
  mobileOpened: true,
  footerOffset: false,
  expanded: true

  /* options:
    childIndentValue?: number;
    displayToAnonymousUser?: boolean;
    expanded?: boolean;
    footerOffset?: boolean;
    hideChangeCompanyButton?: boolean;
    hideCompanyNameInCompactMode?: boolean;
    hideLogoutButton?: boolean;
    lockExpansion?: boolean;
    mobileOpened?: boolean;
    mobileViewWidthBreakpoint?: number;
    useDefaultHelpItem?: boolean;
    disableBackdropClose?: boolean;
    suppressChangeCompanyDialog?: boolean;
  */
};

export const SidenavHeaderConfig = {
  displayName: true, // if we want to display name of the user
  avatarImageUrl: null, // if we want to show a specific avatar picture e.g. user's avatar
  optionalSubtitle: null
};

export const SidenavFooterConfig = {
  items: []
};
