import { Injectable } from '@angular/core';
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Router
} from '@angular/router';
import { FeatureToggleService } from '@zonar-ui/feature-toggle';
import { Observable, of } from 'rxjs';
import { map, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AppFeatureToggleService implements CanActivate {
  constructor(
    private featureToggleService: FeatureToggleService,
    private router: Router
  ) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> {
    const featureKey = next.data.featureKey;
    if (!featureKey) {
      return of(true);
    }
    return this.featureToggleService.valueForKey(featureKey).pipe(
      map((value) => {
        if (!value || !Boolean(value)) {
          this.router.navigate(['/overview/issue']);
        }
        return Boolean(value);
      })
    );
  }
}
