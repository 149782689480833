import { HttpParams } from '@angular/common/http';
import { ParamsModel } from '@src/app/models/params-model';
import { ZonarUITableModel } from '@zonar-ui/table';
import { DateTime } from 'luxon';
import * as _ from 'lodash';

export const isLocalDevEnv = () => {
  // in order for unit tests (ran from localhost:9876) to pass, they need to be excluded from 'local'
  return (
    window?.location?.origin?.includes('localhost:4200') ||
    window?.location?.origin?.includes('local.dev')
  );
};

// This function is used on distinctUntilChanged when calling issue and event view aggergation api
export function checkRouteParamsAreChanged(
  previous: ParamsModel,
  current: ParamsModel
): boolean {
  if (previous && current) {
    return _.isEqual(
      _.omit(current, ['page', 'per_page', 'sort']), //ignoring page, per_page, sort param changes
      _.omit(previous, ['page', 'per_page', 'sort'])
    );
  }
  return false;
}

/**
 * Compare 2 values, only support primitive type, string, array
 * @param a
 * @param b
 */
export function equals(a, b) {
  if (typeof a !== typeof b) {
    return false;
  } else if (Array.isArray(a) && Array.isArray(b)) {
    return isArrayEquals(a, b);
  } else {
    return a == b;
  }
}

export function isArrayEquals(arr1: Array<any>, arr2: Array<any>) {
  if (arr1?.length !== arr2?.length) {
    return false;
  }
  for (let i = 0; i < arr1?.length; i++) {
    if (arr1[i] !== arr2[i]) {
      return false;
    }
  }
  return true;
}

export const formatTimeStamp = (incomingTimeStamp) => {
  const newFormat = DateTime.DATETIME_SHORT;
  return DateTime.fromISO(incomingTimeStamp).toLocaleString(newFormat);
};

// Function to return time format as per UX design document.
// https://zonarsystems.atlassian.net/wiki/spaces/ZDG/pages/137926803713/Standards+Formats+of+Measurement#Standard-format-for-time-and-duration
export const formatTimeStampForTable = (incomingTimeStamp) => {
  const userTimeZone = DateTime.local().zoneName;
  const dt = DateTime.fromISO(incomingTimeStamp).setZone(userTimeZone);
  const formattedDateTime = dt.toFormat('MM/dd/yyyy HH:mm:ss');

  const timeZoneAbbrev = dt.toFormat('ZZZZ');

  return `${formattedDateTime} ${timeZoneAbbrev}`;
};

// Source: https://stackoverflow.com/a/53943624
export function repeatTimes(times: number) {
  return {
    [Symbol.iterator]: function* () {
      for (let i = 0; i < times; i++, yield) {}
    }
  };
}

export function formatToStartOfDay(incomingDate) {
  return DateTime.fromJSDate(incomingDate)
    .set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
    .toJSDate();
}

export function isEqual(prev: any, current: any) {
  if (prev === current) {
    return true;
  }
  const typeOfPrev = typeof prev;
  const typeOfCurrent = typeof current;

  if (typeOfPrev !== typeOfCurrent) {
    return false;
  }

  if (typeOfPrev === 'object') {
    const prevKeys = Object.keys(prev);
    return prevKeys.some((k) => {
      if (prev[k] && current[k] && prev[k] !== current[k]) {
        return false;
      }
    });
  }
  return true;
}

export function removeUnMatchedSortField(
  httpParams: HttpParams,
  columns: ZonarUITableModel[]
) {
  const sortByField = httpParams.get('sort');
  if (!sortByField) {
    return httpParams;
  }
  let columnNotMatched = true;
  columns.forEach((c) => {
    if (sortByField.includes(c.columnDef)) {
      columnNotMatched = false;
    }
  });
  if (columnNotMatched) {
    httpParams = httpParams.delete('sort');
  }
  return httpParams;
}

// Add a comma to numbers
export function formatNumberWithCommas(number) {
  return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}

export function removeUTCOffsetFromStartAndEndTime(
  httpParams: HttpParams
): HttpParams {
  const startTime = httpParams.get('startTime');
  if (startTime) {
    httpParams = httpParams.set('startTime', removeUTCOffset(startTime));
  }
  const endTime = httpParams.get('endTime');
  if (endTime) {
    httpParams = httpParams.set('endTime', removeUTCOffset(endTime));
  }
  return httpParams;
}

export function removeUTCOffset(datetime: string): string {
  const dateTimeObj = DateTime.fromJSDate(new Date(datetime));
  return dateTimeObj
    .toUTC()
    .plus({
      minute: dateTimeObj.offset
    })
    .toJSDate()
    .toISOString();
}
