import { Injectable } from '@angular/core';
import { ParamsModel } from '../models/params-model';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SharedService {
  private assetSummaryPageQueryParams$: BehaviorSubject<ParamsModel> =
    new BehaviorSubject<ParamsModel>(null);

  constructor() {}

  public setAssetSummaryPageQueryParams(params: ParamsModel) {
    this.assetSummaryPageQueryParams$.next(params);
  }

  public getAssetSummaryPageQueryParams(): Observable<ParamsModel> {
    return this.assetSummaryPageQueryParams$.asObservable();
  }
}
