import { Injectable } from '@angular/core';

import { Observable, Subject, BehaviorSubject, ReplaySubject } from 'rxjs';
import { distinctUntilChanged, filter, map } from 'rxjs/operators';

import { TranslateService } from '@zonar-ui/i18n';

import { Translations } from '@app/services/i18n/translations.service';
import { UserCompany } from '@app/models/user.company.model';
import { mockCompany } from '@src/mocks/user.company.mock';

@Injectable({
  providedIn: 'root'
})
export class SelectedCompanyService {
  private _translated;
  private _onDestroy$ = new Subject<void>();
  private _currentCompanyId$ = new BehaviorSubject<string>(null);
  private _currentCompany$ = new BehaviorSubject<UserCompany>(mockCompany);

  constructor(
    public translateService: TranslateService,
    public translations: Translations
  ) {}

  public getCompanyFromSideNav(): Observable<UserCompany> {
    return this._currentCompany$.asObservable().pipe(
      filter((values) => values !== null && values.value !== 'default'),
      map((values) => values)
    );
  }

  public getUserSelectedCompanyId(): Observable<string> {
    return this._currentCompanyId$.asObservable().pipe(
      distinctUntilChanged(),
      filter((id) => !!id)
    );
  }

  public getUserSelectedCompanyIdVal(): string {
    return this._currentCompanyId$.getValue();
  }

  setCompany(incomingCompany: UserCompany): void {
    const companyId = incomingCompany?.value;

    this._currentCompany$.next(incomingCompany);
    this._currentCompanyId$.next(companyId);
  }
}
