<div class="overview-container">
  <app-page-title>
    {{ translateService.get(translations.overview.pageTitle) | async | translate
    }}
  </app-page-title>
  <nav mat-tab-nav-bar [tabPanel]="tabPanel">
    <ng-container *ngFor="let link of navLinks">
      <a
        mat-tab-link
        #rla="routerLinkActive"
        [active]="activeLink === link"
        (click)="handleNavLinkClick(link)"
        *ngIf="link.featureKey$ | async"
        routerLinkActive>
        {{ translateService.get(translations.overview.matTabs[link.label]) |
        async | translate }}
      </a>
    </ng-container>
  </nav>
  <mat-tab-nav-panel #tabPanel>
    <router-outlet></router-outlet>
  </mat-tab-nav-panel>
</div>
