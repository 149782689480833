// Use this for any shared settings that cross environment bounds.
// TODO: Setup explicit permissions if applicable to your Frontend web application.
// See https://gitlab.com/ZonarSystems/tools/pattern-library/-/tree/master/projects/zonar-ui-auth#Permissions for details

export const permissions = {
  defaultRedirectUrl: '/',
  defaultPermPrefix: 'gtc_maintenance',
  routes: [['/', 'read:assets']]
};
//TODO: change appName (should match datadog app name)
export const appName = 'GTCx Maintenance';
// Typically we would use this to report to Datadog RUM if we have it installed.
export const appVersion = '1.1.0';
