import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  NavigationCancel,
  NavigationEnd,
  NavigationError,
  NavigationStart,
  Router
} from '@angular/router';
import { AuthService } from '@auth0/auth0-angular';
import { Subject } from 'rxjs';
import { debounceTime, filter, map, take, takeUntil } from 'rxjs/operators';

import { FaviconsService } from '@zonar-ui/core';
import { PendoService } from '@zonar-ui/analytics';
import { PermissionsService } from '@zonar-ui/auth';
import { GTCxMobileHelperService } from '@zonar-ui/gtcx-mobile-helper';

import { appName } from '@src/environments/shared';
import { DataDogService } from './services/datadog-service/data-dog.service';
import { SelectedCompanyService } from './services/selected-company/selected-company.service';
import { DeviceDetectionService } from './services/device-detection/device-detection.service';
import {
  SidenavGlobalConfig,
  SidenavHeaderConfig
} from '@app/shared/sidenav.config';
import { UserCompany } from '@app/models/user.company.model';
import { FeatureToggleService } from '@zonar-ui/feature-toggle';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnDestroy, OnInit {
  anges;
  loading = true;
  title = appName;
  sidenavConfig = SidenavGlobalConfig;
  sidenavHeader = SidenavHeaderConfig;
  onDestroy$ = new Subject<void>();
  constructor(
    private router: Router,
    private favicon: FaviconsService,
    private datadog: DataDogService,
    private selectedCompanyService: SelectedCompanyService,
    private auth: AuthService,
    private pendoService: PendoService,
    private permissionsService: PermissionsService,
    public device: DeviceDetectionService,
    private featureToggleService: FeatureToggleService,
    private gtcxMobileHelperService: GTCxMobileHelperService
  ) {
    this.auth.isAuthenticated$
      .pipe(
        filter((isAuthenticated) => Boolean(isAuthenticated)),
        takeUntil(this.onDestroy$)
      )
      .subscribe(() => {
        this.datadog.startSessionReplayRecording();
      });
    this.router.events.subscribe((event) => {
      switch (true) {
        case event instanceof NavigationStart: {
          this.loading = true;
          break;
        }
        case event instanceof NavigationEnd:
        case event instanceof NavigationCancel:
        case event instanceof NavigationError: {
          this.loading = false;
          break;
        }
        default: {
          break;
        }
      }
    });
  }

  ngOnInit() {
    this.permissionsService
      .getPermissions()
      .pipe(
        takeUntil(this.onDestroy$),
        debounceTime(250),
        filter((permissions) => Boolean(permissions))
      )
      .subscribe(() => {
        this.pendoService.initialize();
      });
  }

  ngOnDestroy() {
    this.onDestroy$.next();
    this.onDestroy$.complete();
    this.datadog.stopSessionReplayRecording();
  }

  switchCompany(incomingCompany: UserCompany): void {
    this.permissionsService
      .getCompanyMap()
      .pipe(
        take(1),
        map((companies) => {
          if (companies && companies[incomingCompany.value]) {
            // Setting Company context to use it on Zonar-Owner-Id for selecting right profile.
            this.permissionsService.setCurrentCompanyContext(
              companies[incomingCompany.value]
            );
          }
          this.featureToggleService.initializeDevCycle(incomingCompany.value);
          this.selectedCompanyService.setCompany(incomingCompany);
          this.gtcxMobileHelperService.setCurrentCompany(incomingCompany.value);
        })
      )
      .subscribe();
  }

  onSideNavMobileMenuButtonToggled(event): void {
    this.sidenavConfig.mobileOpened = event;
  }
}
