import {
  ChangeDetectionStrategy,
  Component,
  OnDestroy,
  OnInit
} from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  ActivatedRoute,
  Router,
  Routes,
  RouterModule,
  NavigationEnd,
  NavigationStart
} from '@angular/router';
import { MatCardModule } from '@angular/material/card';
import { MatTabsModule } from '@angular/material/tabs';
import { CoreModule, FlexLayoutModule } from '@angular/flex-layout';

import { Subject, of } from 'rxjs';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';

// zonar-ui pattern library
import { TranslateModule, TranslateService } from '@zonar-ui/i18n';

import { Translations } from '@app/services/i18n/translations.service';

// child components
import { PageTitleComponent } from '@app/components/page-title/page-title.component';
import { filter, map, startWith, takeUntil, tap } from 'rxjs/operators';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { SharedService } from '@src/app/shared/shared.service';
import {
  FeatureToggleService,
  FeatureTogglesModule
} from '@zonar-ui/feature-toggle';

@Component({
  selector: 'app-overview-container',
  standalone: true,
  imports: [
    FlexLayoutModule,
    CoreModule,
    CommonModule,
    RouterModule,
    MatCardModule,
    MatTabsModule,
    MatIconModule,
    MatButtonModule,
    NgxSkeletonLoaderModule,
    PageTitleComponent,
    TranslateModule,
    FeatureTogglesModule
  ],
  templateUrl: './overview-container.component.html',
  styleUrls: ['./overview-container.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class OverviewContainerComponent implements OnInit, OnDestroy {
  public pageTitle: string;

  navLinks = [];
  activeLink = null;

  public assetSummaryPageParams = null;

  private onDestroy$ = new Subject<void>();
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    public translateService: TranslateService,
    public translations: Translations,
    private sharedService: SharedService,
    private featureToggleService: FeatureToggleService
  ) {
    this.sharedService.getAssetSummaryPageQueryParams().subscribe((params) => {
      this.assetSummaryPageParams = params;
    });
  }

  ngOnInit(): void {
    this.navLinks =
      !!this.route.routeConfig && !!this.route.routeConfig.children.length
        ? this.buildNavigatingItems(this.route.routeConfig.children)
        : [];
    this.activeLink = this.navLinks[0];
    this.subscribeForRouteChanges();
  }

  buildNavigatingItems(routes: Routes) {
    return routes
      .filter((route) => route.data)
      .map(({ path = '', data }) => ({
        path: path,
        label: data.label,
        url: `overview/${path}`,
        featureKey$: data.featureKey
          ? this.featureToggleService
              .valueForKey(data.featureKey)
              .pipe(map((v) => Boolean(v)))
          : of(true)
      }));
  }

  subscribeForRouteChanges() {
    this.router.events
      .pipe(
        takeUntil(this.onDestroy$),
        filter((event) => event instanceof NavigationEnd),
        startWith(this.router), // let the component load the initial state from the injected Route, so that the event
        tap((event: NavigationEnd) => {
          let currentTabRoute = event.url.split('/').at(-1);
          this.pageTitle = event.url.split('/').at(-2);
          this.activeLink = this.navLinks.find((link) =>
            currentTabRoute.startsWith(link.path)
          );
        })
      )
      .subscribe();
  }

  public handleNavLinkClick(link: any) {
    if (link.path.includes('assetSummary') && this.assetSummaryPageParams) {
      this.router.navigate([link.url], {
        queryParams: this.assetSummaryPageParams,
        queryParamsHandling: ''
      });
    } else {
      // reset assetSummary params when it's routing to different url
      if (this.assetSummaryPageParams) {
        this.assetSummaryPageParams = null;
      }
      this.router.navigate([link.url], {
        queryParamsHandling: 'preserve'
      });
    }
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }
}
