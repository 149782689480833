import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from '@auth0/auth0-angular';

import { OverviewContainerComponent } from './containers/overview-container/overview-container.component';
import { Views } from './models/views.model';
import { environment } from '@src/environments/environment';
import { AppFeatureToggleService } from './app-feature-toggle.guard';

const routes: Routes = [
  {
    path: 'overview',
    component: OverviewContainerComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: '',
        redirectTo: 'assetSummary',
        pathMatch: 'full'
      },
      {
        path: 'assetSummary',
        loadComponent: () =>
          import(
            './containers/asset-summary-container/asset-summary/asset-summary-container.component'
          ).then((m) => m.AssetSummaryContainerComponent),
        data: {
          label: 'assetSummaryView',
          view: Views.ASSET_SUMMARY,
          featureKey: environment.devCycle.assetSummaryFeature
        },
        canActivate: [AuthGuard, AppFeatureToggleService]
      },
      {
        path: 'issue',
        loadComponent: () =>
          import(
            './containers/issue-view-container/issue-view-container.component'
          ).then((m) => m.IssueViewContainerComponent),
        data: { label: 'issueView', view: Views.ISSUE },
        canActivate: [AuthGuard]
      },
      {
        path: 'event',
        loadComponent: () =>
          import(
            './containers/event-view-container/event-view-container.component'
          ).then((m) => m.EventViewContainerComponent),
        data: { label: 'eventView', view: Views.EVENT },
        canActivate: [AuthGuard]
      }
    ]
  },

  {
    path: 'status',
    loadComponent: () =>
      import('./containers/status-container/status-container.component').then(
        (m) => m.StatusContainerComponent
      )
  },

  {
    path: '**',
    redirectTo: 'overview',
    pathMatch: 'full'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule {}
